import React, { useState } from 'react'

import { Acordion, LineHR } from 'app/components'
import {
  AcordionContent,
  LinkDocumentation,
  NameDocumentation,
} from '../../HolderCompany/styles'
import { IconPaperClip } from '../../../components/Icons'
import { FamilyGroupDetail } from '../../../../domain/entities/familyGroupPADDetail'
import { companyPADService } from '../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuth } from '../../../slices/authSlice'
import * as C from 'app/components'
import { saveDocumentation } from '../../../slices/companyPAD'
import DocumentationModal from '../../HolderCompany/DocumentationModal'
import { TGetAfilliationDTO } from '../../../../domain/responseModel'

type TDocumentation = {
  data: TGetAfilliationDTO | any
  detailSocio?: FamilyGroupDetail | null
  titular?: any[]
}

const Documentation = (props: TDocumentation) => {
  const dispatch = useDispatch()
  const [modalDocumentation, setModalDocumentation] = useState(true)
  const { token } = useSelector(selectAuth)
  const familyMemberState = props.data?.afiliacion?.estadoBackOffice || 0
  const isAfilliation = true

  const handleSubmitDocumentation = (values: any) => {
    const valuesAfilliation = {
      idAfiliacion: props.data?.afiliacion?.id,
      tipoAfiliacion: props.data?.afiliacion?.tipoAfiliacion,
    }
    dispatch(saveDocumentation(values, isAfilliation, valuesAfilliation))
  }

  return (
    <>
      <Acordion
        title="Documentación"
        open={true}
        headerButton={[
          <C.Button
            type="button"
            onClick={() => {
              setModalDocumentation(!modalDocumentation)
            }}
            outline
            disabled={false}
            isLoading={false}
            key={'new-documentation-btn'}
          >
            Agregar documentación
          </C.Button>,
        ]}
      >
        <AcordionContent>
          {props.data?.documentaciones?.map((e: any) => (
            <>
              <LinkDocumentation>
                <IconPaperClip centered={false} size={'xs'} />
                <NameDocumentation
                  onClick={() => {
                    companyPADService.downloadDocumentation(
                      e.urlArchivo,
                      token,
                      e.nombre
                    )
                  }}
                >
                  {e.nombre}
                </NameDocumentation>
              </LinkDocumentation>
              <LineHR color="greyC6" />
            </>
          ))}
        </AcordionContent>
      </Acordion>
      <DocumentationModal
        onSubmit={handleSubmitDocumentation}
        show={modalDocumentation}
        setShow={setModalDocumentation}
        idTipoEstado={familyMemberState}
        idTitular={props.data.afiliacion?.dni}
      />
    </>
  )
}

export default Documentation
