import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { TForm } from 'app/Pages/Policies/constants'

import {
  getPolicies,
  selectPolicies,
  getPoliciesExport,
  selectExportPolicyIsFetching,
  selectPoliciesIsFetching,
  selectPoliciesNumberOfPages,
  getPoliciesRejectedExport,
  selectExportRejectedPoliciesIsFetching,
  getPoliciesAbandonQuotedExport,
  selectPolicyAbandonedQuotesIsFetching,
} from 'app/slices/policySlice'
import { getStates, selectStates } from 'app/slices/stateSlice'
import { getAllProvincies, selectProvincies } from 'app/slices/provinceSlice'

import { viewModelPolicies } from 'app/Pages/Policies/presenters'

import { LoadingSpinner } from 'app/components'
import InsuranceForm from 'app/Pages/Policies/Form'

interface IPoliciesPage {
  path: string
}

const Policies = (props: IPoliciesPage) => {
  const [policiesForView, setPoliciesForView] = useState([])
  let history = useHistory()

  const dispatch = useDispatch()
  const policies = useSelector(selectPolicies)
  const getPoliciesIsFetching = useSelector(selectPoliciesIsFetching)
  const policiesNumberOfPages = useSelector(selectPoliciesNumberOfPages)
  const allProvincies = useSelector(selectProvincies)
  const exportPolicyIsFetching = useSelector(selectExportPolicyIsFetching)
  const states = useSelector(selectStates)
  const exportRejectedPoliciesIsFetching = useSelector(
    selectExportRejectedPoliciesIsFetching
  )
  const abandonedQuotesIsFetching = useSelector(
    selectPolicyAbandonedQuotesIsFetching
  )

  useEffect(() => {
    dispatch(getAllProvincies())
  }, [dispatch])

  useEffect(() => {
    dispatch(getStates({ idEstado: 2 }))
  }, [dispatch])

  useEffect(() => {
    const mapPoliciesForView: any = viewModelPolicies({ policies, states })

    setPoliciesForView(mapPoliciesForView)
  }, [policies, states])

  const handleOnPressSubmit = (values: TForm) => {
    if (values.resetPageOnSubmit) {
      const resetPageNumber = Object.assign(values, { pagina: 0 })

      return dispatch(getPolicies(resetPageNumber))
    }
    if (values.exportList === 1) return dispatch(getPoliciesExport(values))

    if (values.exportList === 0) return dispatch(getPolicies(values))
  }

  const handleOnPressItem = (element: any, key: any) =>
    history.push(`${props.path}/${element[key]}`)

  const handleOnPressExportRejectedPolicies = () =>
    dispatch(getPoliciesRejectedExport())

  const handleOnPressExportAbadonedQuotes = () =>
    dispatch(getPoliciesAbandonQuotedExport())

  return (
    <LoadingSpinner
      isLoading={
        exportPolicyIsFetching ||
        abandonedQuotesIsFetching ||
        exportRejectedPoliciesIsFetching
      }
      fullPage={true}
    >
      <InsuranceForm
        provincies={allProvincies}
        onSubmit={handleOnPressSubmit}
        states={states}
        paginationPageCount={policiesNumberOfPages}
        data={policiesForView}
        isLoading={getPoliciesIsFetching}
        onPressItem={handleOnPressItem}
        onPressExportRejectedPolicies={handleOnPressExportRejectedPolicies}
        onPressExportAbandonedQuotes={handleOnPressExportAbadonedQuotes}
      />
    </LoadingSpinner>
  )
}

export default Policies
